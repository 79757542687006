import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import { navigate } from "gatsby"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import HalfAssedBox from "../../../../../components/half-assed-box"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import Paper from "../../../../../components/paper"
import PaperShred from "../../../../../components/paper-shred"
import RotateLetter from "../../../../../components/rotate-letter"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"
import Textarea from "../../../../../components/textarea"

const Principle = ({ letter, title, children, ad }) => {
  return (
    <HalfAssedBox>
      <Columns collapse={[true, false]}>
        <Column width="content">
          <RotateLetter>{letter}</RotateLetter>
        </Column>
        <Column>
          <Stack space={6}>
            <Stack space={3}>
              <Heading as="h3" level={4}>
                {title}
              </Heading>
              <Paragraph>{children}</Paragraph>
            </Stack>
            {ad && (
              <Stack space={2}>
                <Text sans color="whisper">
                  Hier ein Beispiel:
                </Text>
                <Paper p={[3, 4, 6]}>
                  <Box
                    sx={{
                      fontSize: [1, 2, 3],
                      fontFamily: "mono",
                    }}
                  >
                    {ad}
                  </Box>
                </Paper>
                <Text size={0} sans color="whisper" align="right">
                  Textquelle:{" "}
                  <Box
                    as="a"
                    href="https://www.travelworks.de/work-and-travel-australien.html"
                    rel="nofollow"
                    sx={{
                      textDecoration: "underline",
                      color: "inherit",
                      ":hover, :focus": { textDecoration: "none" },
                    }}
                  >
                    travelworks.de
                  </Box>
                </Text>
              </Stack>
            )}
          </Stack>
        </Column>
      </Columns>
    </HalfAssedBox>
  )
}

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const { submitForm, getAnswer } = useCourseStore()

  const answer = getAnswer({
    courseId: "reiseerinnerung",
    chapterId: "01-suedfrankreich",
    taskId: "argumente-sammeln",
  })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "reiseerinnerung",
          chapterId: "01-suedfrankreich",
          taskId: "work-and-travel",
        })
        navigate(
          "/kurse/reiseerinnerung/01-suedfrankreich/work-and-travel/zusammenfassung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Eine Work & Travel-Anzeige schreiben" />
      <Stack>
        <Heading as="h2" level={2}>
          Eine Work & Travel-Anzeige schreiben
        </Heading>
        <Paragraph>
          Stell dir vor, Hölderlin wäre durch eine Zeitungsannonce auf die
          Stelle in Südfrankreich aufmerksam geworden. Welche Argumente hätten
          ihn wohl zum Aufbruch bewegt? Entwerfe eine Werbeanzeige für ein Work
          & Travel-Programm in Südfrankreich, die Hölderlin damals gelesen haben
          könnte. Wecke seine Reiselust, indem du Beschreibungen aus dem Gedicht
          und deine eben gesammelten Argumente in den Text mit einfließen lässt.
        </Paragraph>
        <Paragraph>
          Ein Werbetext ist klassischerweise nach dem sogenannten AIDA-Prinzip
          aufgebaut:
        </Paragraph>

        <Stack>
          <Principle
            letter="A"
            title="Attention"
            ad={`Endlich raus, endlich frei – die Welt erwartet dich! Du bist ein
                              Traveller auf der Suche nach einmaligen Erlebnissen und träumst
                              du von der großen Freiheit?`}
          >
            Zunächst wird die Aufmerksamkeit der Leser*innen geweckt. Das
            geschieht über einen ungewöhnlichen Einstieg, zum Beispiel einen
            Ausruf oder eine Frage.
          </Principle>
          <Principle
            letter="I"
            title="Interest"
            ad={`
              Neue Orte sehen und neue Freunde kennen lernen? Jobs nach Wahl
              annehmen, auf der Reise Geld verdienen und mal etwas ganz anderes
              machen? Work and Travel weltweit, das bedeutet für ein Gehalt oder
              Kost und Logis zu arbeiten und herumzureisen.`}
          >
            Anschließend wird ein Interesse am Produkt erzeugt, indem es näher
            beschrieben wird.
          </Principle>
          <Principle
            letter="D"
            title="Desire"
            ad={`Du besserst deine Reisekasse auf, verbesserst deine
                      Fremdsprachenkenntnisse, erlebst Abenteuer pur und lernst Land und
                      Leute besonders intensiv kennen.`}
          >
            Um den Kaufwunsch zu fördern werden Argumente und Vorteile
            vorgebracht.
          </Principle>
          <Principle
            letter="A"
            title="Action"
            ad={`Was tun? Na, anpacken, die Welt erleben und dein Wunschland zu
            deinem zweiten Zuhause machen!`}
          >
            Zuletzt sollen die Leser*innen zu einer Handlung aktiviert werden:
            Zum Kauf oder der Buchung des Beworbenen.
          </Principle>
        </Stack>

        <Note variant="task">
          Nimm dir diese Struktur zur Vorlage und verfasse einen pfiffigen Text,
          der Hölderlin angesprochen hätte! Beziehe dabei auch deine gesammelten
          Argumente mit ein und greife Beschreibungen aus dem Gedicht mit auf.
        </Note>

        <Grid columns={[1, 2]} space={[6, 6, 8]}>
          {answer && (
            <Paper p={[6, 6, 8]} sx={{ height: "100%" }}>
              <Stack space={6}>
                <Heading level={5}>Deine Argumente</Heading>
                <Stack space={3}>
                  <Heading color="whisper">Die Reise gibt</Heading>
                  <Text size={[3, 3, 3, 4]}>{answer?.["reise-gibt"]}</Text>
                </Stack>
                <Stack space={3}>
                  <Heading color="whisper">Die Reise nimmt</Heading>
                  <Text size={[3, 3, 3, 4]}>{answer?.["reise-nimmt"]}</Text>
                </Stack>
              </Stack>
            </Paper>
          )}
          <Paper p={[6, 6, 8]} sx={{ height: "100%" }}>
            <Stack space={6}>
              <Heading level={5}>Beschreibungen aus ›Andenken‹</Heading>
              <Stack space={1}>
                <PaperShred>Geh aber nun und grüße</PaperShred>
                <PaperShred>Die schöne Garonne</PaperShred>
                <PaperShred>Auf seidnen Boden,</PaperShred>
                <PaperShred>Es beginnet nemlich der Reichtum</PaperShred>
                <PaperShred>Im Meere.</PaperShred>
                <PaperShred>Das Schöne der Erd'</PaperShred>
                <PaperShred>Die Feiertage der Stadt,</PaperShred>
                <PaperShred>Es nehmet aber Und giebt</PaperShred>
                <PaperShred>Gedächtniß die See,</PaperShred>
                <PaperShred>An Traubenbergen</PaperShred>
              </Stack>
            </Stack>
          </Paper>
        </Grid>

        <Textarea
          id="attention"
          name="attention"
          label="Attention – errege Aufmerksamkeit"
          rows={8}
        />
        <Textarea
          id="interest"
          name="interest"
          label="Interest – wecke das Interesse"
          rows={8}
        />
        <Textarea
          id="desire"
          name="desire"
          label="Desire – führe Argumente auf"
          rows={8}
        />
        <Textarea
          id="action"
          name="action"
          label="Action – motiviere zu einer Handlung"
          rows={8}
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
